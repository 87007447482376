import Router from 'next/router';
import { OFFER_TYPES, REDEMPTION_TYPES, DIRECT_TYPES } from "../../constants/app";
var onlineOnly = "/static/offers/online-only.svg";
var masterCardVisa = "/static/offers/mastercard-visa@2x.png";
var masterCardVisaAmex = "/static/offers/mastercard-visa-amex@2x.png";
import moment from 'moment-timezone';
export var getOfferContent = function getOfferContent(offerType) {
  switch (offerType) {
    case OFFER_TYPES.MALL:
      return {
        ctaText: 'Shop now'
      };
    case OFFER_TYPES.MCO:
      return {
        ctaText: 'Activate'
      };
    case OFFER_TYPES.NURSERY:
      return {
        ctaText: 'Link card'
      };
    default:
      return {};
  }
};
export var pointsTermCalculator = function pointsTermCalculator(offerType, dollarSpend, discountType) {
  if (offerType === OFFER_TYPES.MALL || offerType === OFFER_TYPES.DIRECT) {
    return "per $".concat(dollarSpend, " spent");
  }
  if (discountType === 'PERCENTAGE') {
    return dollarSpend === '0' ? 'per $1 spent' : "per $1 with a $".concat(dollarSpend, "+ spend");
  }
  return "with a $".concat(dollarSpend, "+ spend");
};
export var directEarnOffers = function directEarnOffers(offers) {
  return offers && offers.filter(function (offer) {
    return offer.offerType === DIRECT_TYPES.EARN || offer.offerType === DIRECT_TYPES.EARN_BONUS;
  });
};
export var directEarnCurrentOffer = function directEarnCurrentOffer(offers) {
  if (!offers) {
    return [];
  }
  if (offers.length > 1) {
    return offers.filter(function (offer) {
      return offer.offerType === DIRECT_TYPES.EARN_BONUS;
    })[0];
  }
  return offers[0];
};
export var merchantPriority = function merchantPriority(merchant) {
  if (merchant.merchantIdOLM) {
    return OFFER_TYPES.MALL;
  }
  if (merchant.merchantIdDirect) {
    return OFFER_TYPES.DIRECT;
  }
  if (merchant.merchantIdMCO) {
    return OFFER_TYPES.MCO;
  }
  return null;
};
export var getRedemptionContent = function getRedemptionContent(type, offerType, isVisaAmexEnabled) {
  var logoImg = isVisaAmexEnabled ? masterCardVisaAmex : masterCardVisa;
  switch (type) {
    case REDEMPTION_TYPES.ONLINE:
      {
        return {
          text: 'Online only',
          img: offerType === OFFER_TYPES.MALL ? onlineOnly : logoImg
        };
      }
    case REDEMPTION_TYPES.INSTORE:
      return {
        text: 'In-store only',
        img: logoImg
      };
    case REDEMPTION_TYPES.BOTH:
      return {
        text: 'In-store & online',
        img: logoImg
      };
    default:
      return {};
  }
};
export var isExpired = function isExpired(offerType, offerEndDate) {
  if (offerType === OFFER_TYPES.MCO) {
    return moment(offerEndDate).isBefore(moment.tz('Australia/Sydney'), 'day');
  }
  return false;
};
export var isRebateExpired = function isRebateExpired(offerType, offerEndDate) {
  if (offerType === OFFER_TYPES.MALL) {
    return moment(offerEndDate).isBefore(moment.tz('Australia/Sydney'), 'day');
  }
  return false;
};
export var actualPointsCalculator = function actualPointsCalculator(offer) {
  var discountType = offer.discountType,
    offerType = offer.offerType,
    pointsEarn = offer.pointsEarn,
    prevPoints = offer.prevPoints,
    offerEndDate = offer.offerEndDate,
    conversionRate = offer.conversionRate;
  var rebateExpired = isRebateExpired(offerType, offerEndDate);
  if (prevPoints && !rebateExpired) {
    return {
      showPrevPoints: prevPoints,
      actualPoints: pointsEarn
    };
  }
  if (discountType === 'PERCENTAGE') {
    var newPoints = pointsEarn / conversionRate;
    return {
      showPrevPoints: null,
      actualPoints: newPoints
    };
  }
  return {
    showPrevPoints: null,
    actualPoints: rebateExpired ? prevPoints : pointsEarn
  };
};
export var calculateDaysLeft = function calculateDaysLeft(endDate) {
  var daysLeft = moment(endDate).endOf('days').diff(moment().format('YYYY-MM-DD'), 'days');
  if (!Number.isFinite(daysLeft) || daysLeft < 0) {
    return null;
  }
  switch (daysLeft) {
    case 1:
      return "".concat(daysLeft, " day left");
    case 0:
      return 'expires today';
    default:
      return "".concat(daysLeft, " days left");
  }
};
export var showDaysLeft = function showDaysLeft(offerType, offerEndDate, callout) {
  var expiredOffer = isExpired(offerType, offerEndDate);
  if (offerType === OFFER_TYPES.MALL && callout && offerEndDate) {
    return calculateDaysLeft(offerEndDate);
  }
  if (offerType === OFFER_TYPES.MCO && !expiredOffer) {
    return calculateDaysLeft(offerEndDate);
  }
  return false;
};
export var commonOfferProps = function commonOfferProps(offer) {
  return {
    merchantId: offer.merchantId,
    key: offer.offerIdMls,
    offerType: OFFER_TYPES.MCO,
    offerIdMls: offer.offerIdMls,
    offerImg: offer.offerImageURL,
    pointsEarn: offer.discount,
    discountType: offer.discountType,
    dollarSpend: offer.minTransactionAmountToRedeem,
    conversionRate: offer.pointsConversionRate,
    importantInfo: offer.longDescription,
    merchantLogo: offer.merchantLogoURL,
    offerEndDate: offer.eventEndDate,
    redemptionType: offer.redemptionType,
    hasPebble: false
  };
};
export var commonOlmProps = function commonOlmProps(merchant) {
  var merchantId = merchant.merchantId,
    rebate = merchant.rebate,
    is_elevated = merchant.is_elevated,
    details = merchant.details,
    was_rebate = merchant.was_rebate,
    is_new = merchant.is_new,
    merchantLogo = merchant.merchantLogo,
    promoted = merchant.promoted;
  var _ref = rebate || {},
    start_date = _ref.start_date,
    end_date = _ref.end_date;
  return {
    merchantId: merchantId,
    key: merchantId,
    offerType: OFFER_TYPES.MALL,
    pointsEarn: rebate ? rebate.rebate_user : 0,
    prevPoints: is_elevated ? was_rebate && was_rebate.rebate_user : 0,
    dollarSpend: 1,
    callout: is_elevated,
    importantInfo: details && details[0].info_special_terms,
    merchantLogo: merchantLogo,
    offerEndDate: end_date,
    offerStartDate: start_date,
    onClick: function onClick() {
      return Router.push("/merchant?slug=".concat(merchantId.replace(/\+/g, '%2B')), "/".concat(merchantId)).then(function () {
        return window.scrollTo(0, 0);
      });
    },
    newStore: is_new,
    specialTerms: promoted && promoted.prepend_special_terms,
    offer: merchant,
    lazyLoad: true
  };
};